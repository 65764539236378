import { MetricsFilterData } from 'pages/Metrics'
import request from './request'
import useRequest, { Config } from './swr'

type LoansResponse = {
  count: number
  next: string
  previous: string
  results: Loan[]
}

type LoanWithNewVA = {
  id: number
  beneficiary_account_number: string
  beneficiary_bank_name?: string
  beneficiary_account_name?: string
}

export const useLoans = (
  params: {
    search?: string // searches in fields "borrower_first_name", "borrower_last_name", "student_id_number", "reference_code"
    sortField?: string
    sortDir?: SortDirection
    page?: number // max 10 items per page, returns 404 if page is invalid
    partnerStatus?: Loan['partner_status'] // partner status filter
    borrowerProfileDegree?: BorrowerProfileDegree // degree filter (e.g. "Bachelor of Science (BS)")
    isManager?: boolean
    dateActivated?: number
    loanStatus?: LoanStatusDashboard
    partnerName?: string
    productName?: string
    program?: string
    submittedAfter?: string
    submittedBefore?: string
    activatedAfter?: string
    activatedBefore?: string
  } = { page: 1 },
  config?: Config<LoansResponse>
) => {
  const ordering =
    params.sortDir === 'descend' ? `-${params.sortField}` : params.sortField
  const url = `/partner/loans/`
  return useRequest<LoansResponse>(
    {
      method: 'GET',
      url: url,
      params: {
        search: params.search,
        ordering: params.sortField ? ordering : '',
        page: params.page,
        partner_status: params.partnerStatus,
        datetime_submitted_after: params.submittedAfter,
        datetime_submitted_before: params.submittedBefore,
        datetime_disbursed_after: params.activatedAfter,
        datetime_disbursed_before: params.activatedBefore,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        degree: params.borrowerProfileDegree,
        date_activated: params.dateActivated,
        status: params.loanStatus,
        partner_name: params.partnerName,
        product_name: params.productName,
        program: params.program,
      },
    },
    config
  )
}

export const postLoan = (loanId: number) =>
  request.post<{ detail: string; loan_id: number }>(
    `/partner/loan/${loanId}/post/`
  )

export const postLoans = (loanIds: number[]) =>
  request.post('/partner/loans/post_multiple/', { ids: loanIds })

export const useLoansVArequired = (config?: Config<Loan[]>) => {
  const url = `/partner/loans/multiple_va/`
  return useRequest<Loan[]>(
    {
      method: 'GET',
      url: url,
    },
    config
  )
}

export const postLoansVA = (loansWithNewVA: LoanWithNewVA[]) =>
  request.post(`/partner/loans/multiple_va/`, { loansWithNewVA })

export const useLoan = (
  loanId?: number,
  params?: { lang: string },
  config?: Config<Loan>
) => {
  const url = `/partner/loan/${loanId}/`
  return useRequest<Loan>(
    loanId
      ? {
          method: 'GET',
          url: url,
          params,
        }
      : null,
    config
  )
}

export const useLoanMonthlyMetrics = (data?: MetricsFilterData) => {
  return useRequest<LoanMonthlyMetrics>({
    method: 'GET',
    url: '/partner/report/loans/monthly_metrics/',
    params: { ...data },
  })
}

export const getLoansFileData = (
  params: {
    submittedAfter?: string
    submittedBefore?: string
    activatedAfter?: string
    activatedBefore?: string
    loanStatus?: LoanStatusDashboard
    dateActivated?: number
    productName?: string
    program?: string
    partnerName?: string
    partnerStatus?: Loan['partner_status'] // partner status filter
  } = {}
) =>
  request.get<string>(`partner/report/applications/export/`, {
    headers: { Accept: 'text/csv' },
    params: {
      date_submitted_after: params.submittedAfter,
      date_submitted_before: params.submittedBefore,
      date_activated_after: params.activatedAfter,
      date_activated_before: params.activatedBefore,
      date_activated: params.dateActivated,
      status: params.loanStatus,
      product_name: params.productName,
      program: params.program,
      partner_name: params.partnerName,
      partner_status: params.partnerStatus,
    },
  })
