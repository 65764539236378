export enum Login {
  heading = 'pages.login.heading',
  subheading = 'pages.login.subheading',
  alert = 'pages.login.alert',
}

export enum Register {
  heading = 'pages.register.heading',
  subheading = 'pages.register.subheading',
  agreement = 'pages.register.agreement',
  termsAndConditions = 'pages.register.termsAndConditions',
  privacyPolicy = 'pages.register.privacyPolicy',
}

export enum ForgotPassword {
  heading = 'pages.forgotPassword.heading',
  subheading = 'pages.forgotPassword.subheading',
  success = 'pages.forgotPassword.success',
}

export enum SetPassword {
  heading = 'pages.setPassword.heading',
  subheading = 'pages.setPassword.subheading',
}

export enum Home {
  title = 'pages.home.title',
  quickLink = 'pages.home.quickLink',
  quickLinkSubtitle = 'pages.home.quickLinkSubtitle',
  quickLinkBlankTitle = 'pages.home.quickLinkBlankTitle',
  quickLinkBlankSubtitle = 'pages.home.quickLinkBlankSubtitle',
  news = 'pages.home.news',
  widgetInfo = 'pages.home.widgetInfo',
  widgetInfoStudent = 'pages.home.widgetInfoStudent',
  widgetTitleDisbursement = 'pages.home.widgetTitleDisbursement',
  widgetTitleStudent = 'pages.home.widgetTitleStudent',
  greeting = 'pages.home.greeting',
  welcome = 'pages.home.welcome',
  chooseLanguage = 'pages.home.chooseLanguage',
  applications = 'pages.home.applications',
  applicationsSubheading = 'pages.home.applicationsSubheading',
  reports = 'pages.home.reports',
  reportsSubheading = 'pages.home.reportsSubheading',

  onboardingHeading = 'pages.home.onboardingHeading',
  onboardingDescription = 'pages.home.onboardingDescription',
  onboardingTrack = 'pages.home.onboardingTrack',
  onboardingTrackDescription = 'pages.home.onboardingTrackDescription',
  onboardingDownloadReports = 'pages.home.onboardingDownloadReports',
  onboardingDownloadReportsDescription = 'pages.home.onboardingDownloadReportsDescription',
  onboardingLeads = 'pages.home.onboardingLeads',
  onboardingLeadsDescription = 'pages.home.onboardingLeadsDescription',

  partnershipSurveyHeading = 'pages.home.partnershipSurveyHeading',
  partnershipSurveyDescription = 'pages.home.partnershipSurveyDescription',

  restrictedAccessTitle = 'pages.home.restrictedAccessTitle',
  restrictedAccessSubtitle = 'pages.home.restrictedAccessSubtitle',

  emptyActivation = 'pages.home.emptyActivation',
  emptyActivationSubtitle = 'pages.home.emptyActivationSubtitle',
  emptyActivationAddLeads = 'pages.home.emptyActivationAddLeads',
  emptyActivationButtonText = 'pages.home.emptyActivationButtonText',
}

export enum Applications {
  title = 'pages.applications.title',
  tabAll = 'pages.applications.tabAll',
  tabReportGenerated = 'pages.applications.tabReportGenerated',
  tabReportReceived = 'pages.applications.tabReportReceived',
  revisionOverviewBadge = 'pages.applications.revisionOverviewBadge',
  revisionOverviewTitle = 'pages.applications.revisionOverviewTitle',
  revisionOverviewSubtitle = 'pages.applications.revisionOverviewSubtitle',

  headingName = 'pages.applications.headingName',
  headingMobileNumber = 'pages.applications.headingMobileNumber',
  headingEmail = 'pages.applications.headingEmail',
  headingSchool = 'pages.applications.headingSchool',
  headingIdNum = 'pages.applications.headingIdNum',
  headingDegree = 'pages.applications.headingDegree',
  headingProgram = 'pages.applications.headingProgram',
  headingDateSubmitted = 'pages.applications.headingDateSubmitted',
  headingDateActivated = 'pages.applications.headingDateActivated',
  headingCoborrower = 'pages.applications.headingCoborrower',
  headingDisbursedAmount = 'pages.applications.headingDisbursedAmount',
  headingApprovedAmount = 'pages.applications.headingApprovedAmount',
  headingRefundAmount = 'pages.applications.headingRefundAmount',
  headingRequestedAmount = 'pages.applications.headingRequestedAmount',
  headingAmount = 'pages.applications.headingAmount',
  headingReference = 'pages.applications.headingReference',
  headingLoanStatus = 'pages.applications.headingLoanStatus',
  headingLoanProduct = 'pages.applications.headingLoanProduct',
  headingReportStatus = 'pages.applications.headingReportStatus',
  headingAction = 'pages.applications.headingAction',
  filterDateActivated = 'pages.applications.filterDateActivated',
  filterCourse = 'pages.applications.filterCourse',
  filterLoanStatus = 'pages.applications.filterLoanStatus',
  filterPartnerName = 'pages.applications.filterPartnerName',

  pendingReportStatus = 'pages.applications.pendingReportStatus',
  newReportStatus = 'pages.applications.newReportStatus',
  postedReportStatus = 'pages.applications.postedReportStatus',

  degreeAll = 'pages.applications.degreeAll',

  degreeBS = 'pages.applications.degreeBS',
  degreeBA = 'pages.applications.degreeBA',
  degreeB = 'pages.applications.degreeB',
  degreeBFA = 'pages.applications.degreeBFA',
  degreeMS = 'pages.applications.degreeMS',
  degreeMA = 'pages.applications.degreeMA',
  degreeM = 'pages.applications.degreeM',
  degreeD = 'pages.applications.degreeD',
  degreePhD = 'pages.applications.degreePhD',
  degreeMD = 'pages.applications.degreeMD',
  degreeJD = 'pages.applications.degreeJD',
  degreeShort = 'pages.applications.degreeShort',

  degreeD1 = 'pages.applications.degreeD1',
  degreeD2 = 'pages.applications.degreeD2',
  degreeD3 = 'pages.applications.degreeD3',
  degreeS1 = 'pages.applications.degreeS1',
  degreeS2 = 'pages.applications.degreeS2',
  degreeS3 = 'pages.applications.degreeS3',

  allDays = 'pages.applications.allDays',
  day = 'pages.applications.day',
  days = 'pages.applications.days',
  applyFilter = 'pages.applications.applyFilter',
  filterBy = 'pages.applications.filterBy',
  sendNotification = 'pages.applications.sendNotification',
  reset = 'pages.applications.reset',

  postSuccess = 'pages.applications.postSuccess',
  postSuccessMessage = 'pages.applications.postSuccessMessage',
  postErrorMessage = 'pages.applications.postErrorMessage',
  overviewTitle = 'pages.applications.overviewTitle',
  labelPayment = 'pages.applications.labelPayment',
  labelServiceFee = 'pages.applications.labelServiceFee',
  labelRequestedAmount = 'pages.applications.labelRequestedAmount',
  labelLoanDuration = 'pages.applications.labelLoanDuration',
  labelInterest = 'pages.applications.labelInterest',
  labelTotalAmount = 'pages.applications.labelTotalAmount',
  tenor = 'pages.applications.tenor',

  VAUpdateTitle = 'pages.applications.VAUpdateTitle',
  needUpdating = 'pages.applications.needUpdating',
  studentID = 'pages.applications.studentID',
  approvedPrincipal = 'pages.applications.approvedPrincipal',
  bankSelectionSectionTitle = 'pages.applications.bankSelectionSectionTitle',
  inputVASectionTitle = 'pages.applications.inputVASectionTitle',
  VAHelper = 'pages.applications.VAHelper',
  modalsubmitTitle = 'pages.applications.modalsubmitTitle',
  modalsubmitInfo = 'pages.applications.modalsubmitInfo',
  bankNamePlaceholder = 'pages.applications.bankNamePlaceholder',
  VAPlaceholder = 'pages.applications.VAPlaceholder',

  customView = 'pages.applications.customView',
  customViewPopoverSubheading = 'pages.applications.customViewPopoverSubheading',
  customViewEmpty = 'pages.applications.customViewEmpty',
  customViewCreate = 'pages.applications.customViewCreate',
  customViewSelect = 'pages.applications.customViewSelect',
  customViewSelectPlaceholder = 'pages.applications.customViewSelectPlaceholder',
  customViewModalMainHeading = 'pages.applications.customViewModalMainHeading',
  customViewModalMainSubheading = 'pages.applications.customViewModalMainSubheading',
  customViewTitleInputLabel = 'pages.applications.customViewTitleInputLabel',
  customViewTitleInputPlaceholder = 'pages.applications.customViewTitleInputPlaceholder',
  customViewAccessInputLabel = 'pages.applications.customViewAccessInputLabel',
  customViewAccessOptionTeam = 'pages.applications.customViewAccessOptionTeam',
  customViewAccessOptionSelf = 'pages.applications.customViewAccessOptionSelf',
  customViewActionCancel = 'pages.applications.customViewActionCancel',
  customViewActionCreate = 'pages.applications.customViewActionCreate',
  customViewActionSaveAsNew = 'pages.applications.customViewActionSaveAsNew',
  customViewActionOverwrite = 'pages.applications.customViewActionOverwrite',
  customViewActionOverwriteConfirm = 'pages.applications.customViewActionOverwriteConfirm',
  customViewOverwriteConfirmDescription = 'page.applications.customViewOverwriteConfirmDescription',
  customViewActionDelete = 'pages.applications.customViewActionDelete',
  customViewActionAddNew = 'pages.applications.customViewActionAddNew',
  customViewConfirmChange = 'pages.applications.customViewConfirmChange',
}

export enum Reports {
  title = 'pages.reports.title',
  subheading = 'pages.reports.subheading',
  reportSectionTitle = 'pages.reports.reportSectionTitle',
  filterDegree = 'pages.reports.filterDegree',
  filterYear = 'pages.reports.filterYear',
  degree = 'pages.reports.degree',
  year = 'pages.reports.year',
  byNumber = 'pages.reports.byNumber',
  byAmount = 'pages.reports.byAmount',
}

export enum DisbursementReports {
  title = 'pages.disbursementReports.title',
  subheading = 'pages.disbursementReports.subheading',
  partnerName = 'pages.disbursementReports.partnerName',
  bankReferenceCode = 'pages.disbursementReports.bankReferenceCode',
  dateDisbursement = 'pages.disbursementReports.dateDisbursement',
  numberOfActivation = 'pages.disbursementReports.numberOfActivation',
  disbursedAmount = 'pages.disbursementReports.disbursedAmount',
  modalTitle = 'pages.disbursementReports.modalTitle',
  modalContent = 'pages.disbursementReports.modalContent',
  modalContentDowloaded = 'pages.disbursementReports.modalContentDowloaded',
  modalAction = 'pages.disbursementReports.modalAction',
  modalActionWithSendNotif = 'pages.disbursementReports.modalActionWithSendNotif',
  modalAlertSafari = 'pages.disbursementReports.modalAlertSafari',
  postSuccess = 'pages.disbursementReports.postSuccess',
  postSuccessMessage = 'pages.disbursementReports.postSuccessMessage',
  totalRefundAmount = 'pages.disbursementReports.totalRefundAmount',
  actualFundAmountDisbursed = 'pages.disbursementReports.actualFundAmountDisbursed',
}

export enum Search {
  title = 'pages.search.title',
  subheading = 'pages.search.subheading',
}

export enum Profile {
  title = 'pages.profile.title',

  email = 'pages.profile.email',
  emailPlaceholder = 'pages.profile.emailPlaceholder',
  labelPlaceholder = 'pages.profile.labelPlaceholder',

  contact = 'pages.profile.contact',
  contactDescription = 'pages.profile.contactDescription',
  typePlaceholder = 'pages.profile.typePlaceholder',
  typePhone = 'pages.profile.typePhone',
  typeMobile = 'pages.profile.typeMobile',

  editBasicInfo = 'pages.profile.editBasicInfo',
  partnerLogo = 'pages.profile.partnerLogo',
  basicInfo = 'pages.profile.basicInfo',
  addressInfo = 'pages.profile.addressInfo',
  provincePlaceholder = 'pages.profile.provincePlaceholder',
  cityPlaceholder = 'pages.profile.cityPlaceholder',

  noAddress = 'pages.profile.noAddress',
  noEmail = 'pages.profile.noEmail',
  noContact = 'pages.profile.noContact',

  overview = 'pages.profile.overview',
  contactInfo = 'pages.profile.contactInfo',
  partnerProfile = 'pages.profile.partnerProfile',
  labelSchool = 'pages.profile.labelSchool',
  labelAddress = 'pages.profile.labelAddress',
  deletePhoneTitle = 'pages.profile.deletePhoneTitle',
  deletePhoneMessage = 'pages.profile.deletePhoneMessage',
  deleteEmailTitle = 'pages.profile.deleteEmailTitle',
  deleteEmailMessage = 'pages.profile.deleteEmailMessage',
  headingPhone = 'pages.profile.headingPhone',
  headingEmail = 'pages.profile.headingEmail',
}

export enum Administrator {
  titleStep1 = 'pages.Administrator.titleStep1',
  descStep1 = 'pages.Administrator.descStep1',
  titleStep2 = 'pages.Administrator.titleStep2',
  descStep2 = 'pages.Administrator.descStep2',
  titleStep3 = 'pages.Administrator.titleStep3',
  descStep3 = 'pages.Administrator.descStep3',

  titleBlankState = 'pages.Administrator.titleBlankState',
  descBlankState = 'pages.Administrator.descBlankState',
  actionBlankState = 'pages.Administrator.actionBlankState',

  titleModalAddAdmin = 'pages.Administrator.titleModalAddAdmin',
  descModalAddAdmin = 'pages.Administrator.descModalAddAdmin',

  addAdminBtn = 'pages.Administrator.addAdminBtn',
  alertMaxUser = 'pages.Administrator.alertMaxUser',
  addUserSuccessTitle = 'pages.Administrator.addUserSuccessTitle',
  addUserSuccessDesc = 'pages.Administrator.addUserSuccessDesc',

  removeFormAdmin = 'pages.Administrator.removeFormAdmin',
}

export enum LeadsWelcome {
  title = 'pages.leadsWelcome.title',
  firstOptionTitle = 'pages.leadsWelcome.firstOptionTitle',
  firstOptionContent = 'pages.leadsWelcome.firstOptionContent',
  secondOptionTitle = 'pages.leadsWelcome.secondOptionTitle',
  secondOptionContent = 'pages.leadsWelcome.secondOptionContent',
  thirdOptionTitle = 'pages.leadsWelcome.thirdOptionTitle',
  thirdOptionContent = 'pages.leadsWelcome.thirdOptionContent',
  registerTitle = 'pages.leadsWelcome.registerTitle',
  registerContent = 'pages.leadsWelcome.registerContent',
}

export enum LeadsContent {
  title = 'pages.leadsContent.title',

  leadTypeFilter = 'pages.leadsContent.leadTypeFilter',
  schoolFilter = 'pages.leadsContent.schoolFilter',
  loanStatusFilter = 'pages.leadsContent.loanStatusFilter',
  levelFilter = 'pages.leadsContent.levelFilter',

  leadTypeFilterPlacholder = 'pages.leadsContent.leadTypeFilterPlacholder',
  schoolFilterPlaceholder = 'pages.leadsContent.schoolFilterPlaceholder',
  loanStatusFilterPlaceholder = 'pages.leadsContent.loanStatusFilterPlaceholder',
  levelFilterPlaceholder = 'pages.leadsContent.levelFilterPlaceholder',

  addLeadTitle = 'pages.leadsContent.addLeadTitle',
  addLeadSubTitle = 'pages.leadsContent.addLeadSubTitle',
  addLeadContent = 'pages.leadsContent.addLeadContent',

  dateInfo = 'pages.leadsContent.dateInfo',
  leadInfoTitle = 'pages.leadsContent.leadInfoTitle',
  totalLead = 'pages.leadsContent.totalLead',
  thisMonthLead = 'pages.leadsContent.thisMonthLead',
  lastWeekLead = 'pages.leadsContent.lastWeekLead',

  convertionRateTitle = 'pages.leadsContent.convertionRateTitle',
  convertionRateRegistration = 'pages.leadsContent.convertionRateRegistration',
  convertionRateApplication = 'pages.leadsContent.convertionRateApplication',
  convertionRateActivation = 'pages.leadsContent.convertionRateActivation',

  tabAllLeads = 'pages.leadsContent.tabAllLeads',
  tabDraft = 'pages.leadsContent.tabDraft',
  tabErrors = 'pages.leadsContent.tabErrors',

  headingResendSMS = 'pages.leadsContent.headingResendSMS',
  headingType = 'pages.leadsContent.headingType',
  headingName = 'pages.leadsContent.headingName',
  headingEmail = 'pages.leadsContent.headingEmail',
  headingUsername = 'pages.leadsContent.headingUsername',
  headingSchool = 'pages.leadsContent.headingSchool',
  headingLoanProduct = 'pages.leadsContent.headingLoanProduct',
  headingDateAdded = 'pages.leadsContent.headingDateAdded',
  headingAddedByName = 'pages.leadsContent.headingAddedByName',
  headingLeadStatus = 'pages.leadsContent.headingLeadStatus',
  headingLeadProgress = 'pages.leadsContent.headingLeadProgress',
  headingAction = 'pages.leadsContent.headingAction',
  headingDatetimeCreated = 'pages.leadsContent.datetimeCreated',
  headingUploadedBy = 'pages.leadsContent.headingUploadedBy',
  headingErrorFile = 'pages.leadsContent.headingErrorFile',
  headingIdNumber = 'pages.leadsContent.headingIdNumber',

  resendSMSHoverInfo = 'pages.leadsContent.resendSMSHoverInfo',
  unableToResendSMSHoverInfo = 'pages.leadsContent.unableToResendSMSHoverInfo',

  resendSMSSuccessTitle = 'pages.leadsContent.resendSMSSuccessTitle',
  resendSMSSuccessDesc = 'pages.leadsContent.resendSMSSuccessDesc',
  resendSMSErrorTitle = 'pages.leadsContent.resendSMSErrorTitle',
  resendSMSErrorDesc = 'pages.leadsContent.resendSMSFailDesc',

  filterBy = 'pages.leadsContent.filterBy',

  firstModalTitle = 'pages.leadsContent.firstModalTitle',
  firstModalContent = 'pages.leadsContent.firstModalContent',
  secondModalTitle = 'pages.leadsContent.secondModalTitle',
  secondModalContent = 'pages.leadsContent.secondModalContent',
  thirdModalTitle = 'pages.leadsContent.thirdModalTitle',
  thirdModalContent = 'pages.leadsContent.thirdModalContent',
  fourthModalTitle = 'pages.leadsContent.fourthModalTitle',
  fourthModalContent = 'pages.leadsContent.fourthModalContent',
  fifthModalTitle = 'pages.leadsContent.fifthModalTitle',
  fifthModalContent = 'pages.leadsContent.fifthModalContent',
  sixthModalTitle = 'pages.leadsContent.sixthModalTitle',
  sixthModalContent = 'pages.leadsContent.sixthModalContent',

  leadsPopoverTitle = 'pages.leadsContent.leadsPopoverTitle',
  leadsPopoverContent = 'pages.leadsContent.leadsPopoverContent',
  convertionRatePopoverTitle = 'pages.leadsContent.convertionRatePopoverTitle',
  convertionRatePopoverContent = 'pages.leadsContent.convertionRatePopoverContent',
  progressPopoverTitle = 'pages.leadsContent.progressPopoverTitle',
  progressPopoverContent = 'pages.leadsContent.progressPopoverContent',
  draftsPopoverTitle = 'pages.leadsContent.draftsPopoverTitle',
  draftsPopoverContent = 'pages.leadsContent.draftsPopoverContent',
  submitPopoverTitle = 'pages.leadsContent.submitPopoverTitle',
  submitPopoverContent = 'pages.leadsContent.submitPopoverContent',

  createLeadsModalTitle = 'pages.leadsContent.createLeadsModalTitle',
  StudentInfoLabel = 'pages.leadsContent.StudentInfoLabel',
  firstNameFieldLabel = 'pages.leadsContent.firstNameFieldLabel',
  firstNameFieldPlaceholder = 'pages.leadsContent.firstNameFieldPlaceholder',
  lastNameFieldLabel = 'pages.leadsContent.lastNameFieldLabel',
  lastNameFieldPlaceholder = 'pages.leadsContent.lastNameFieldPlaceholder',
  emailFieldLabel = 'pages.leadsContent.emailFieldLabel',
  mobileNumberFieldLabel = 'pages.leadsContent.mobileNumberFieldLabel',
  mobileNumberFieldPlaceholder = 'pages.leadsContent.mobileNumberFieldPlaceholder',
  loanProductFieldLabel = 'pages.leadsContent.loanProductFieldLabel',
  loanProductFieldPlaceholder = 'pages.leadsContent.LoanProductFieldPlaceholder',
  dateOfBirthFieldLabel = 'pages.leadsContent.dateOfBirthFieldLabel',
  dateOfBirthFieldPlaceholder = 'pages.leadsContent.dateOfBirthFieldPlaceholder',
  dateOfBirthFieldValidationError = 'pages.leadsContent.dateOfBirthFieldValidationError',
  schoolFieldLabel = 'pages.leadsContent.schoolFieldLabel',
  additionalInfoLabel1 = 'pages.leadsContent.additionalInfoLabel1',
  additionalInfoLabel2 = 'pages.leadsContent.additionalInfoLabel2',
  degreeFieldLabel = 'pages.leadsContent.degreeFieldLabel',
  degreeFieldPlaceholder = 'pages.leadsContent.degreeFieldPlaceholder',
  courseFieldLabel = 'pages.leadsContent.courseFieldLabel',
  courseFieldPlaceholder = 'pages.leadsContent.courseFieldPlaceholder',
  IDNumberFieldLabel = 'pages.leadsContent.IDNumberFieldLabel',
  IDNumberFieldPlaceholder = 'pages.leadsContent.IDNumberFieldPlaceholder',
  addressFieldLabel = 'pages.leadsContent.addressFieldLabel',
  addressFieldPlaceholder = 'pages.leadsContent.addressFieldPlaceholder',
  cityFieldLabel = 'pages.leadsContent.cityFieldLabel',
  cityFieldPlaceholder = 'pages.leadsContent.cityFieldPlaceholder',
  provinceFieldLabel = 'pages.leadsContent.provinceFieldLabel',
  provinceFieldPlaceholder = 'pages.leadsContent.provinceFieldPlaceholder',
  previewBatchLeadsModalTitle = 'pages.leadsContent.previewBatchLeadsModalTitle',
  previewBatchLeadsModalUploadSuccess = 'pages.leadsContent.previewBatchLeadsModalUploadSuccess',

  uploadStatusModalSuccessTitle = 'pages.leadsContent.uploadStatusModalSuccessTitle',
  uploadStatusModalErrorTitle = 'pages.leadsContent.uploadStatusModalErrorTitle',
  uploadStatusModalSuccessDescription = 'pages.leadsContent.uploadStatusModalSuccessDescription',
  uploadStatusModalErrorDescription = 'pages.leadsContent.uploadStatusModalErrorDescription',

  uploadProcessModalTitle = 'pages.leadsContent.uploadProcessModalTitle',
  uploadProcessModalDescription = 'pages.leadsContent.uploadProcessModalDescription',

  uploadBatchLeadsModalTitle = 'pages.leadsContent.uploadBatchLeadsModalTitle',
  uploadBatchLeadsModalHint = 'pages.leadsContent.uploadBatchLeadsModalHint',
  uploadBatchLeadsModalUploadFileSection = 'pages.leadsContent.uploadBatchLeadsModalUploadFileSection',
  uploadBatchLeadsModalUploadFileSectionPlacholder = 'pages.leadsContent.uploadBatchLeadsModalUploadFileSectionPlacholder',
  uploadBatchLeadsTitleDescription = 'pages.leadsContent.uploadBatchLeadsTitleDescription',
  uploadBatchLeadsDescription = 'pages.leadsContent.uploadBatchLeadsDescription',

  downloadCSVSampleButton = 'pages.leadsContent.downloadCSVSampleButton',

  sendSMSModalTitle = 'pages.leadsContent.sendSMSModalTitle',
  sendSMSModalContent = 'pages.leadsContent.sendSMSModalContent',
  thankYouModalTitle = 'pages.leadsContent.thankYouModalTitle',
  thankYouModalContent = 'pages.leadsContent.thankYouModalContent',
  aggregateTitle = 'pages.leadsContent.aggregateTitle',
  aggregateTotal = 'pages.leadsContent.aggregateTotal',
  aggregateRegistration = 'pages.leadsContent.aggregateRegistration',
  aggregateApplication = 'pages.leadsContent.aggregateApplication',
  aggregateActivated = 'pages.leadsContent.aggregateActivated',
  aggregateAmount = 'pages.leadsContent.aggregateAmount',

  tooltipProgress = 'pages.leadsContent.tooltipProgress',
  tooltipProgressNotYetRegister = 'pages.leadsContent.tooltipProgressNotYetRegister',
  tooltipProgressRegistered = 'pages.leadsContent.tooltipProgressRegistered',
  tooltipProgressSubmitted = 'pages.leadsContent.tooltipProgressSubmitted',
  tooltipProgressActivated = 'pages.leadsContent.tooltipProgressActivated',
}

export enum Risk {
  title = 'pages.risk.title',

  selectCohort = 'pages.risk.selectCohort',
  selectCohortPlaceholder = 'pages.risk.selectCohortPlaceholder',

  summary = 'pages.risk.summary',
  dateActivated = 'pages.risk.dateActivated',
  dateActivatedPopover = 'pages.risk.dateActivatedPopover',
  nextRepaymentDeadline = 'pages.risk.nextRepaymentDeadline',
  nextRepaymentDeadlinePopover = 'pages.risk.nextRepaymentDeadlinePopover',
  finalDisbursement = 'pages.risk.finalDisbursement',
  finalDisbursementPopover = 'pages.risk.finalDisbursementPopover',
  summaryCoachmarkingContent = 'pages.risk.summaryCoachmarkingContent',

  performance = 'pages.risk.performance',
  activations = 'pages.risk.activations',
  asOf = 'pages.risk.asOf',
  npl = 'pages.risk.npl',
  netPrincipalNPL = 'pages.risk.netPrincipalNPL',
  approvedPrincipal = 'pages.risk.approvedPrincipal',
  nplStudentCount = 'pages.risk.nplStudentCount',
  nonNplStudentCount = 'pages.risk.nonNplStudentCount',
  performanceCoachmarkingContent = 'pages.risk.performanceCoachmarkingContent',

  loans = 'pages.risk.loans',
  headingRepaymentStatus = 'pages.risk.headingRepaymentStatus',
  headingName = 'pages.risk.headingName',
  headingIdNum = 'pages.risk.headingIdNum',
  headingCourse = 'pages.risk.headingCourse',
  headingReference = 'pages.risk.headingReference',
  headingLoanProduct = 'pages.risk.headingLoanProduct',
  headingDateActivated = 'pages.risk.headingDateActivated',
  headingInstitution = 'pages.risk.headingInstitution',
  headingReportingDate = 'pages.risk.headingReportingDate',
  headingTotalStudent = 'page.risk.headingTotalStudent',
  headingDownloadReport = 'page.risk.headingDownloadReport',
  loansCoachmarkingContent = 'pages.risk.loansCoachmarkingContent',

  MonthlyReport = 'pages.risk.MonthlyReport',
  StudentList = 'pages.risk.StudentList',
}

export enum PartnerProfile {
  title = 'pages.partnerProfile.title',

  joinedDate = 'pages.partnerProfile.joinedDate',
  population = 'pages.partnerProfile.population',
  activations = 'pages.partnerProfile.activations',
  program = 'pages.partnerProfile.program',
  completion = 'pages.partnerProfile.completion',

  basicInformationSection = 'pages.partnerProfile.basicInformationSection',
  programSection = 'pages.partnerProfile.programSection',
  paymentsSection = 'pages.partnerProfile.paymentsSection',
  imagesSection = 'pages.partnerProfile.imagesSection',

  addressSectionTitle = 'pages.partnerProfile.addressSectionTitle',
  addressSectionContent = 'pages.partnerProfile.addressSectionContent',

  socialMediaAccountsSectionTitle = 'pages.partnerProfile.socialMediaAccountsSectionTitle',
  socialMediaAccountsSectionContent = 'pages.partnerProfile.socialMediaAccountsSectionContent',

  contactNumberSectionTitle = 'pages.partnerProfile.contactNumberSectionTitle',
  contactNumberSectionContent = 'pages.partnerProfile.contactNumberSectionContent',

  contactNumberRemoveModalTitle = 'pages.partnerProfile.contactNumberRemoveModalTitle',
  contactNumberRemoveModalDescription = 'pages.partnerProfile.contactNumberRemoveModalDescription',

  populationSectionTitle = 'pages.partnerProfile.populationSectionTitle',
  populationSectionContent = 'pages.partnerProfile.populationSectionContent',
  populationSectionDate = 'pages.partnerProfile.populationSectionDate',

  contactPersonSectionTitle = 'pages.partnerProfile.contactPersonSectionTitle',
  contactPersonSectionContent = 'pages.partnerProfile.contactPersonSectionContent',

  contactPersonRemoveModalTitle = 'pages.partnerProfile.contactPersonRemoveModalTitle',
  contactPersonRemoveModalDescription = 'pages.partnerProfile.contactPersonRemoveModalDescription',

  programSectionTitle = 'pages.partnerProfile.programSectionTitle',
  programsSectionText = 'pages.partnerProfile.programsSectionText',
  undergraduateProgramsSectionTitle = 'pages.partnerProfile.undergraduateProgramsSectionTitle',
  graduateProgramsSectionTitle = 'pages.partnerProfile.graduateProgramsSectionTitle',
  shortCoursesSectionTitle = 'pages.partnerProfile.shortCoursesSectionTitle',

  registrationFeesSectionTitle = 'pages.partnerProfile.registrationFeesSectionTitle',
  registrationFeesSectionContent = 'pages.partnerProfile.registrationFeesSectionContent',

  paymentPoliciesSectionTitle = 'pages.partnerProfile.paymentPoliciesSectionTitle',
  paymentPoliciesSectionContent = 'pages.partnerProfile.paymentPoliciesSectionContent',

  internalFinancingProgramSectionTitle = 'pages.partnerProfile.internalFinancingProgramSectionTitle',
  internalFinancingProgramSectionContent = 'pages.partnerProfile.internalFinancingProgramSectionContent',

  paymentDeadlinesSectionTitle = 'pages.partnerProfile.paymentDeadlinesSectionTitle',
  paymentDeadlinesSectionContent = 'pages.partnerProfile.paymentDeadlinesSectionContent',

  imagesSectionTitle = 'pages.partnerProfile.imagesSectionTitle',
  imagesSectionContent = 'pages.partnerProfile.imagesSectionContent',
  schoolPhoto = 'pages.partnerProfile.schoolPhoto',
  schoolLogo = 'pages.partnerProfile.schoolLogo',
  studentID = 'pages.partnerProfile.studentID',
  schoolBilling = 'pages.partnerProfile.schoolBilling',
  stockPhotos = 'pages.partnerProfile.stockPhotos',
  imageSample = 'pages.partnerProfile.imageSample',
  pictureSchool = 'pages.partnerProfile.pictureSchool',
  pictureLogo = 'pages.partnerProfile.pictureLogo',
  pictureStudentID = 'pages.partnerProfile.pictureStudentID',
  pictureCertificate = 'pages.partnerProfile.pictureCertificate',
  pictureClass = 'pages.partnerProfile.pictureClass',
  fileType = 'pages.partnerProfile.fileType',

  populationModalTitle = 'pages.partnerProfile.populationModalTitle',
  populationModalLabelInput1 = 'pages.partnerProfile.populationModalLabelInput1',
  populationModalPlaceholderInput1 = 'pages.partnerProfile.populationModalPlaceholderInput1',
  populationModalButtonText = 'pages.partnerProfile.populationModalButtonText',

  shortCoursesModalTitle = 'pages.partnerProfile.shortCoursesModalTitle',
  graduateProgramsModalTitle = 'pages.partnerProfile.graduateProgramsModalTitle',
  undergraduateProgramsModalTitle = 'pages.partnerProfile.undergraduateProgramsModalTitle',
  undergraduateProgramsModalLabelInput1 = 'pages.partnerProfile.undergraduateProgramsModalLabelInput1',
  undergraduateProgramsModalPlaceholderInput1 = 'pages.partnerProfile.undergraduateProgramsModalPlaceholderInput1',
  undergraduateProgramsModalLabelInput2 = 'pages.partnerProfile.undergraduateProgramsModalLabelInput2',
  undergraduateProgramsModalPlaceholderInput2 = 'pages.partnerProfile.undergraduateProgramsModalPlaceholderInput2',
  undergraduateProgramsModalLabelInput3 = 'pages.partnerProfile.undergraduateProgramsModalLabelInput3',
  undergraduateProgramsModalPlaceholderInput3 = 'pages.partnerProfile.undergraduateProgramsModalPlaceholderInput3',
  undergraduateProgramsModalButtonText = 'pages.partnerProfile.undergraduateProgramsModalButtonText',

  paymentDeadlineModalTitle = 'pages.partnerProfile.paymentDeadlineModalTitle',
  paymentDeadlineModalTypeLabel = 'pages.partnerProfile.paymentDeadlineModalTypeLabel',
  paymentDeadlineModalTypePlaceholder = 'pages.partnerProfile.paymentDeadlineModalTypePlaceholder',
  paymentDeadlineModalMinRequiredPaymentLabel = 'pages.partnerProfile.paymentDeadlineModalMinRequiredPaymentLabel',
  paymentDeadlineModalMinRequiredPaymentPlaceholder = 'pages.partnerProfile.paymentDeadlineModalMinRequiredPaymentPlaceholder',
  paymentDeadlineModalDateRangeLabel = 'pages.partnerProfile.paymentDeadlineModalDateRangeLabel',

  schoolAddressModalTitle = 'pages.partnerProfile.schoolAddressModalTitle',
  schoolAddressModalInputProvinceLabel = 'pages.partnerProfile.schoolAddressModalInputProvinceLabel',
  schoolAddressModalInputProvincePlaceholder = 'pages.partnerProfile.schoolAddressModalInputProvincePlaceholder',
  schoolAddressModalInputCityLabel = 'pages.partnerProfile.schoolAddressModalInputCityLabel',
  schoolAddressModalInputCityPlaceholder = 'pages.partnerProfile.schoolAddressModalInputCityPlaceholder',
  schoolAddressModalInputDistrictLabel = 'pages.partnerProfile.schoolAddressModalInputDistrictLabel',
  schoolAddressModalInputDistrictPlaceholder = 'pages.partnerProfile.schoolAddressModalInputDistrictPlaceholder',
  schoolAddressModalInputAddressLine1Label = 'pages.partnerProfile.schoolAddressModalInputAddressLine1Label',
  schoolAddressModalInputAddressLine1Placeholder = 'pages.partnerProfile.schoolAddressModalInputAddressLine1Placeholder',
  schoolAddressModalInputAddressLine1Notes = 'pages.partnerProfile.schoolAddressModalInputAddressLine1Notes',
  schoolAddressModalInputAddressLine2Label = 'pages.partnerProfile.schoolAddressModalInputAddressLine2Label',
  schoolAddressModalInputAddressLine2Placeholder = 'pages.partnerProfile.schoolAddressModalInputAddressLine2Placeholder',
  schoolAddressModalInputAddressLine2Notes = 'pages.partnerProfile.schoolAddressModalInputAddressLine2Notes',

  addFacebookURL = 'pages.partnerProfile.addFacebookURL',
  addSchoolFacebookURL = 'pages.partnerProfile.addSchoolFacebookURL',

  facebookAccountModalTitle = 'pages.partnerProfile.facebookAccountModalTitle',
  facebookAccountModalInputLabel = 'pages.partnerProfile.facebookAccountModalInputLabel',
  facebookAccountModalInputPrefix = 'pages.partnerProfile.facebookAccountModalInputPrefix',
  facebookAccountModalInputPlaceholder = 'pages.partnerProfile.facebookAccountModalInputPlaceholder',
  facebookAccountModalStep1 = 'pages.partnerProfile.facebookAccountModalStep1',
  facebookAccountModalStep2 = 'pages.partnerProfile.facebookAccountModalStep2',
  facebookAccountModalStep3 = 'pages.partnerProfile.facebookAccountModalStep3',
  facebookAccountModalStep4 = 'pages.partnerProfile.facebookAccountModalStep4',
  facebookAccountModalStep5 = 'pages.partnerProfile.facebookAccountModalStep5',

  contactNumberModalTitle = 'pages.partnerProfile.contactNumberModalTitle',
  contactNumberModalInputLabel = 'pages.partnerProfile.contactNumberModalInputLabel',
  contactNumberModalDropdownOption1 = 'pages.partnerProfile.contactNumberModalDropdownOption1',
  contactNumberModalDropdownOption2 = 'pages.partnerProfile.contactNumberModalDropdownOption2',

  contactPersonModalTitle = 'pages.partnerProfile.contactPersonModalTitle',
  contactPersonModalLabelInput1 = 'pages.partnerProfile.contactPersonModalLabelInput1',
  contactPersonModalLabelInput2 = 'pages.partnerProfile.contactPersonModalLabelInput2',
  contactPersonModalLabelInput3 = 'pages.partnerProfile.contactPersonModalLabelInput3',
  contactPersonModalLabelInput4 = 'pages.partnerProfile.contactPersonModalLabelInput4',
  contactPersonModalPlaceholderInput1 = 'pages.partnerProfile.contactPersonModalPlaceholderInput1',
  contactPersonModalPlaceholderInput2 = 'pages.partnerProfile.contactPersonModalPlaceholderInput2',
  contactPersonModalPlaceholderInput3 = 'pages.partnerProfile.contactPersonModalPlaceholderInput3',
  contactPersonModalPlaceholderInput4 = 'pages.partnerProfile.contactPersonModalPlaceholderInput4',
  contactPersonModalPlaceholderInput5 = 'pages.partnerProfile.contactPersonModalPlaceholderInput5',
  contactPersonModalButtonText = 'pages.partnerProfile.contactPersonModalButtonText',

  paymentPoliciesModalTitle = 'pages.partnerProfile.paymentPoliciesModalTitle',
  paymentPoliciesModalButtonText = 'pages.partnerProfile.paymentPoliciesModalButtonText',

  imageSampleModalTitle = 'pages.partnerProfile.imageSampleModalTitle',

  tipsForSchoolPhotoTitle = 'pages.partnerProfile.tipsForSchoolPhotoTitle',
  tipsForSchoolPhotoContent1 = 'pages.partnerProfile.tipsForSchoolPhotoContent1',
  tipsForSchoolPhotoContent2 = 'pages.partnerProfile.tipsForSchoolPhotoContent2',
  tipsForSchoolPhotoContent3 = 'pages.partnerProfile.tipsForSchoolPhotoContent3',

  tipsForSchoolLogoTitle = 'pages.partnerProfile.tipsForSchoolLogoTitle',
  tipsForSchoolLogoContent1 = 'pages.partnerProfile.tipsForSchoolLogoContent1',
  tipsForSchoolLogoContent2 = 'pages.partnerProfile.tipsForSchoolLogoContent2',
  tipsForSchoolLogoContent3 = 'pages.partnerProfile.tipsForSchoolLogoContent3',

  tipsForStockPhotosTitle = 'pages.partnerProfile.tipsForStockPhotosTitle',
  tipsForStockPhotosContent1 = 'pages.partnerProfile.tipsForStockPhotosContent1',
  tipsForStockPhotosContent2 = 'pages.partnerProfile.tipsForStockPhotosContent2',
  tipsForStockPhotosContent3 = 'pages.partnerProfile.tipsForStockPhotosContent3',

  tipsForStudentIdTitle = 'pages.partnerProfile.tipsForStudentIdTitle',
  tipsForStudentIdContent1 = 'pages.partnerProfile.tipsForStudentIdContent1',
  tipsForStudentIdContent2 = 'pages.partnerProfile.tipsForStudentIdContent2',
  tipsForStudentIdContent3 = 'pages.partnerProfile.tipsForStudentIdContent3',

  tipsForSchoolBillingTitle = 'pages.partnerProfile.tipsForSchoolBillingTitle',
  tipsForSchoolBilling1 = 'pages.partnerProfile.tipsForSchoolBilling1',
  tipsForSchoolBilling2 = 'pages.partnerProfile.tipsForSchoolBilling2',
  tipsForSchoolBilling3 = 'pages.partnerProfile.tipsForSchoolBilling3',

  internalFinancingProgramModalTitle = 'pages.partnerProfile.internalFinancingProgramModalTitle',
  internalFinancingProgramModalNameFieldLabel = 'pages.partnerProfile.internalFinancingProgramModalNameFieldLabel',
  internalFinancingProgramModalNameFieldPlaceholder = 'pages.partnerProfile.internalFinancingProgramModalNameFieldPlaceholder',
  internalFinancingProgramModalTenorFieldLabel = 'pages.partnerProfile.internalFinancingProgramModalTenorFieldLabel',
  internalFinancingProgramModalTenorFieldPlaceholder = 'pages.partnerProfile.internalFinancingProgramModalTenorFieldPlaceholder',
  internalFinancingProgramModalInterestRateFieldLabel = 'pages.partnerProfile.internalFinancingProgramModalInterestRateFieldLabel',
  internalFinancingProgramModalInterestRateFieldPlaceholder = 'pages.partnerProfile.internalFinancingProgramModalInterestRateFieldPlaceholder',
  internalFinancingProgramModalOtherFeesFieldLabel = 'pages.partnerProfile.internalFinancingProgramModalOtherFeesFieldLabel',
  internalFinancingProgramModalOtherFeesFieldPlaceholder = 'pages.partnerProfile.internalFinancingProgramModalOtherFeesFieldPlaceholder',

  removePaymentDeadlineConfirmationModalTitle = 'pages.partnerProfile.removePaymentDeadlineConfirmationModalTitle',
  removePaymentDeadlineConfirmationModalContent = 'pages.partnerProfile.removePaymentDeadlineConfirmationModalContent',

  removeInternalFinancingProgramConfirmationModalTitle = 'pages.partnerProfile.removeInternalFinancingProgramConfirmationModalTitle',
  removeInternalFinancingProgramConfirmationModalContent = 'pages.partnerProfile.removeInternalFinancingProgramConfirmationModalContent',

  removeProgramConfirmationModalTitle = 'pages.partnerProfile.removeProgramConfirmationModalTitle',
  removeProgramConfirmationModalContent = 'pages.partnerProfile.removeProgramConfirmationModalContent',
}

export enum Metrics {
  title = 'pages.metrics.title',

  itemMetricsTitle = 'pages.metrics.itemMetricsTitle',
  itemBorrowersTitle = 'pages.metrics.itemBorrowersTitle',
  itemCohortsTitle = 'pages.metrics.itemCohortsTitle',
  applicationsTabTitle = 'pages.metrics.applicationsTabTitle',
  activationsTabTitle = 'pages.metrics.activationsTabTitle',
  activationsBarLabel = 'pages.metrics.activationsBarLabel',
  activationsBarTooltipValue = 'pages.metrics.activationsBarTooltipValue',
  activationsBarTooltipCount = 'pages.metrics.activationsBarTooltipCount',
  degreeTabTitle = 'pages.metrics.degreeTabTitle',
  yearLevelTabTitle = 'pages.metrics.yearLevelTabTitle',
  borrowersTabTitle = 'pages.metrics.borrowersTabTitle',

  schoolFilterTitle = 'pages.metrics.schoolFilterTitle',
  monthFilterTitle = 'pages.metrics.monthFilterTitle',
  schoolFilterPlaceholder = 'pages.metrics.schoolFilterPlaceholder',
  mothFilterPlaceholderStart = 'pages.metrics.mothFilterPlaceholderStart',
  mothFilterPlaceholderEnd = 'pages.metrics.mothFilterPlaceholderEnd',

  applicationsEmptyTitle = 'pages.metrics.applicationsEmptyTitle',
  activationsEmptyTitle = 'pages.metrics.activationsEmptyTitle',
  borrowersEmptyTitle = 'pages.metrics.borrowersEmptyTitle',
  cohortsDegreeEmptyTitle = 'pages.metrics.cohortsDegreeEmptyTitle',
  cohortsYearEmptyTitle = 'pages.metrics.cohortsYearEmptyTitle',

  applicationsEmptyDescription = 'pages.metrics.applicationsEmptyDescription',
  activationsEmptyDescription = 'pages.metrics.activationsEmptyDescription',
  borrowersEmptyDescription = 'pages.metrics.borrowersEmptyDescription',
  cohortsDegreeEmptyDescription = 'pages.metrics.cohortsDegreeEmptyDescription',
  cohortsYearEmptyDescription = 'pages.metrics.cohortsYearEmptyDescription',
}

export enum Integrations {
  title = 'pages.integrations.title',

  documentationTitleText = 'pages.integrations.documentationTitleText',
  documentationInfoText = 'pages.integrations.documentationInfoText',
  keyText = 'pages.integrations.keyText',
  testKeyText = 'pages.integrations.testKeyText',
  liveKeyText = 'pages.integrations.liveKeyText',

  keySecurityTextTitle = 'pages.integrations.keySecurityTextTitle',
  keySecurityTextInfo = 'pages.integrations.keySecurityTextInfo',

  redirectText = 'pages.integrations.redirectText',
  placeholderRedirectURLText = 'pages.integrations.placeholderRedirectURLText',

  redirectFunctionalityTextTitle = 'pages.integrations.redirectFunctionalityTextTitle',
  redirectFunctionalityTextInfo = 'pages.integrations.redirectFunctionalityTextInfo',
  functionalityTextNote = 'pages.integrations.functionalityTextNote',

  webhookText = 'pages.integrations.webhookText',
  placeholderWebhookURLText = 'pages.integrations.placeholderWebhookURLText',
  contractSignedWebhookTextTitle = 'pages.integrations.contractSignedWebhookTextTitle',
  contractSignedWebhookTextInfo = 'pages.integrations.contractSignedWebhookTextInfo',
  disbursementWebhookTextTitle = 'pages.integrations.disbursementWebhookTextTitle',
  disbursementWebhookTextInfo = 'pages.integrations.disbursementWebhookTextInfo',
  cancellationWebhookTextTitle = 'pages.integrations.cancellationWebhookTextTitle',
  cancellationWebhookTextInfo = 'pages.integrations.cancellationWebhookTextInfo',
  allStatusWebhookTextTitle = 'pages.integrations.allStatusWebhookTextTitle',
  allStatusWebhookTextInfo = 'pages.integrations.allStatusWebhookTextInfo',

  downloadKeyModalTitle = 'pages.integrations.downloadKeyModalTitle',
  downloadKeyModalContent = 'pages.integrations.downloadKeyModalContent',

  keyHighlightText = 'pages.integrations.keyHighlightText',
  apiKey = 'pages.integrations.apiKey',
}

export enum Admissions {
  title = 'pages.admissions.title',
  subheading = 'pages.admissions.subheading',

  filter = 'pages.admissions.filter',
  allDays = 'pages.admissions.allDays',
  day = 'pages.admissions.day',
  days = 'pages.admissions.days',
  applyFilter = 'pages.admissions.applyFilter',
  filterBy = 'pages.admissions.filterBy',
  reset = 'pages.admissions.reset',
  filterDateApplied = 'pages.admissions.filterDateApplied',
  filterEnrollmentType = 'pages.admissions.filterEnrollmentType',
  filterProgramStudy = 'pages.admissions.filterProgramStudy',
  filterPartnerName = 'pages.admissions.filterPartnerName',

  headingDateApplied = 'pages.admissions.headingDateApplied',
  headingEnrollmentType = 'pages.admissions.headingEnrollmentType',
  headingName = 'pages.admissions.headingName',
  headingMobilePhone = 'pages.admissions.headingMobilePhone',
  headingEmail = 'pages.admissions.headingEmail',
  headingSchool = 'pages.admissions.headingSchool',
  headingProgramStudy = 'pages.admissions.headingProgramStudy',
  headingAction = 'pages.admissions.headingAction',

  overviewHeader = 'pages.admissions.overviewHeader',
  overviewEmail = 'pages.admissions.overviewEmail',
  overviewMobileNumber = 'pages.admissions.overviewMobileNumber',
  overviewDateOfBirth = 'pages.admissions.overviewDateOfBirth',
  overviewEnrollmentType = 'pages.admissions.overviewEnrollmentType',
  overviewDatetimeApplied = 'pages.admissions.overviewDatetimeApplied',
  overviewShsStrand = 'pages.admission.overviewShsStrand',
  overviewSchool = 'pages.admissions.overviewSchool',
  overviewPreviousSchool = 'pages.admissions.overviewPreviousSchool',
  overviewProgramStudy = 'pages.admissions.overviewProgramStudy',
}

export enum Permissions {
  title = 'pages.permissions.title',

  titleStep1 = 'pages.permissions.titleStep1',
  descStep1 = 'pages.permissions.descStep1',

  titleStep2 = 'pages.permissions.titleStep2',
  descStep2 = 'pages.permissions.descStep2',

  titleStep3 = 'pages.permissions.titleStep3',
  descStep3 = 'pages.permissions.descStep3',

  titleBlankState = 'pages.permissions.titleBlankState',
  descBlankState = 'pages.permissions.descBlankState',

  tabAdministrators = 'pages.permissions.tabAdministrators',
  tabRoles = 'pages.permissions.tabRoles',

  administratorsHeading = 'pages.permissions.administratorsHeading',
  rolesHeading = 'pages.permissions.rolesHeading',

  accessText = 'pages.permissions.accessText',
  viewIcon = 'pages.permissions.viewIcon',
  downloadIcon = 'pages.permissions.downloadIcon',
  editIcon = 'pages.permissions.editIcon',
  deleteIcon = 'pages.permissions.deleteIcon',

  headingName = 'pages.permissions.headingName',
  headingEmail = 'pages.permissions.headingEmail',
  headingSchools = 'pages.permissions.headingSchools',
  headingRole = 'pages.permissions.headingRole',
  headingAction = 'pages.permissions.headingAction',
  headingStudentData = 'pages.permissions.headingStudentData',
  allCampusStudentData = 'pages.permissions.allCampusStudentData',
  selectedOnlyCampusStudentData = 'pages.permissions.selectedOnlyCampusStudentData',

  addUserSuccessTitle = 'pages.permissions.addUserSuccessTitle',
  addUserSuccessDesc = 'pages.permissions.addUserSuccessDesc',

  editUserSuccessTitle = 'pages.permissions.editUserSuccessTitle',
  editUserSuccessDesc = 'pages.permissions.editUserSuccessDesc',

  addNewAdminModalHeading = 'pages.permissions.addNewAdminModalHeading',
  addNewAdminModalFirstNameFieldLabel = 'pages.permissions.addNewAdminModalFirstNameFieldLabel',
  addNewAdminModalLastNameFieldLabel = 'pages.permissions.addNewAdminModalLastNameFieldLabel',
  addNewAdminModalEmailFieldLabel = 'pages.permissions.addNewAdminModalEmailFieldLabel',
  addNewAdminModalRoleFieldLabel = 'pages.permissions.addNewAdminModalRoleFieldLabel',
  addNewAdminModalCampusFieldLabel = 'pages.permissions.addNewAdminModalCampusFieldLabel',

  editAdminModalHeading = 'pages.permissions.editAdminModalHeading',
  editAdminModalFirstNameFieldLabel = 'pages.permissions.editAdminModalFirstNameFieldLabel',
  editAdminModalLastNameFieldLabel = 'pages.permissions.editAdminModalLastNameFieldLabel',
  editAdminModalEmailFieldLabel = 'pages.permissions.editAdminModalEmailFieldLabel',
  editAdminModalRoleFieldLabel = 'pages.permissions.editAdminModalRoleFieldLabel',
  editAdminModalCampusFieldLabel = 'pages.permissions.editAdminModalCampusFieldLabel',

  deleteAdminModalHeading = 'pages.permissions.deleteAdminModalHeading',
  deleteAdminModalContent = 'pages.permissions.deleteAdminModalContent',
}

export enum Digest {
  title = 'pages.digest.title',
  documentationTitleText = 'pages.digest.documentationTitleText',
  documentationInfoText = 'pages.digest.documentationInfoText',
  integrationsType = 'pages.digest.integrationsType',
  action = 'pages.digest.action',
  requiredField = 'pages.digest.requiredField',
  copyClipboad = 'pages.digest.copyClipboad',
  // field
  addressLine1 = 'pages.digest.addressLine1',
  addressLine2 = 'pages.digest.addressLine2',
  balanceFromOlderTerms = 'pages.digest.balanceFromOlderTerms',
  city = 'pages.digest.city',
  currentDueTotal = 'pages.digest.currentDueTotal',
  dateOfBirth = 'pages.digest.dateOfBirth',
  degree = 'pages.digest.degree',
  email = 'pages.digest.email',
  firstName = 'pages.digest.firstName',
  guardianFirstName = 'pages.digest.guardianFirstName',
  guardianLastName = 'pages.digest.guardianLastName',
  guardianMobileNumber = 'pages.digest.guardianMobileNumber',
  lastName = 'pages.digest.lastName',
  maritalStatus = 'pages.digest.maritalStatus',
  maxDaysInArrears = 'pages.digest.maxDaysInArrears',
  mobileNumber = 'pages.digest.mobileNumber',
  outstandingBalanceFromPreviousTerm = 'pages.digest.outstandingBalanceFromPreviousTerm',
  placeOfBirth = 'pages.digest.placeOfBirth',
  program = 'pages.digest.program',
  province = 'pages.digest.province',
  school = 'pages.digest.school',
  studentIdNumber = 'pages.digest.studentIdNumber',
  studentStatus = 'pages.digest.studentStatus',
  subDistrict = 'pages.digest.subDistrict',
  totalDueForUpcomingTerm = 'pages.digest.totalDueForUpcomingTerm',
  totalDueFromPreviousTerm = 'pages.digest.totalDueFromPreviousTerm',
  // basic
  course = 'pages.digest.course',
  dateDue = 'pages.digest.dateDue',
  daysInArrears = 'pages.digest.daysInArrears',
  guardianEmail = 'pages.digest.guardianEmail',
  schoolRemainingBalance = 'pages.digest.schoolRemainingBalance',
  year = 'pages.digest.year',
}

export enum Lite {
  product = 'pages.lite.product',
  productDesc = 'pages.lite.productDesc',
  subheading = 'pages.lite.subheading',
  bannerDesc = 'pages.lite.bannerDesc',
  buttonDesc = 'pages.lite.buttonDesc',
  landingDesc = 'pages.lite.landingDesc',
  instalation = 'pages.lite.instalation',
  instalationStep1 = 'pages.lite.instalationStep1',
  instalationStep2 = 'pages.lite.instalationStep2',
  note = 'pages.lite.note',
  copy = 'pages.lite.copy',
  selectSchool = 'pages.lite.selectSchool',
  selectInterface = 'pages.lite.selectInterface',
  selectSize = 'pages.lite.selectSize',
  blankTitle = 'pages.lite.blankTitle',
  blankSubtitle = 'pages.lite.blankSubtitle',
}
