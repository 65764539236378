export enum Buttons {
  login = 'buttons.login',
  register = 'buttons.register',
  resetPassword = 'buttons.resetPassword',
  setPassword = 'buttons.setPassword',
  send = 'buttons.send',
  back = 'buttons.back',
  next = 'buttons.next',
  delete = 'buttons.delete',
  add = 'buttons.add',
  edit = 'buttons.edit',
  viewApplicant = 'buttons.viewApplicant',
  sendNotification = 'buttons.sendNotification',
  downloadReport = 'buttons.downloadReport',
  downloadAndSendNotification = 'buttons.downloadAndSendNotification',
  viewAll = 'buttons.viewAll',
  reportReceived = 'buttons.reportReceived',
  export = 'buttons.export',
  exportReport = 'buttons.exportReport',
  exportAsPDF = 'buttons.exportAsPDF',
  save = 'buttons.save',
  editBasicInfo = 'buttons.editBasicInfo',
  remove = 'buttons.remove',
  cancel = 'buttons.cancel',
  preview = 'buttons.preview',
  submit = 'buttons.submit',
  addLead = 'buttons.addLead',
  submitLead = 'buttons.submitLead',
  viewDraft = 'buttons.viewDraft',
  saveDraft = 'buttons.saveDraft',
  exitAndSaveDraft = 'buttons.exitAndSaveDraft',
  skip = 'buttons.skip',
  skipModal = 'buttons.skipModal',
  additionalInfo = 'buttons.additionalInfo',
  sendSMS = 'buttons.sendSMS',
  addAddress = 'buttons.addAddress',
  addContactNumber = 'buttons.addContactNumber',
  updatePopulation = 'buttons.updatePopulation',
  addEmail = 'buttons.addEmail',
  addMainProgram = 'buttons.addMainProgram',
  batchUpload = 'buttons.batchUpload',
  addGraduateProgram = 'buttons.addGraduateProgram',
  addShortCourse = 'buttons.addShortCourse',
  addFile = 'buttons.addFile',
  otherFee = 'buttons.otherFee',
  internalFinancingProgram = 'buttons.internalFinancingProgram',
  addDeadline = 'buttons.addDeadline',
  details = 'buttons.details',
  goBackToDashboard = 'buttons.goBackToDashboard',
  answerSurvey = 'buttons.answerSurvey',
  saveInternalFinancingProgram = 'buttons.saveInternalFinancingProgram',
  savePaymentDeadline = 'buttons.savePaymentDeadline',
  VAupdate = 'buttons.VAupdate',
  scrollDown = 'buttons.scrollDown',
  applyFilters = 'buttons.applyFilters',
  reset = 'buttons.reset',
  downloadErrorFile = 'buttons.downloadErrorFile',
  EN = 'buttons.EN',
  ID = 'buttons.ID',
  integrationsBasic = 'buttons.IntegrationsBasic',
  integrationsWholesale = 'buttons.IntegrationsWholesale',
  integrationsDocumentation = 'buttons.integrationsDocumentation',
  downloadAPI = 'buttons.downloadAPI',
  previewDocument = 'buttons.previewDocument',
  downloadDocument = 'buttons.downloadDocument',
  download = 'buttons.download',
  inviteNewAdmin = 'buttons.inviteNewAdmin',
}

export enum Links {
  forgotPassword = 'links.forgotPassword',
  getStarted = 'links.getStarted',
  edit = 'links.edit',
}
